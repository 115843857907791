import React from "react"
import { useEffect } from "react"
import { useState } from "react"

import { Modal } from "../../anti/modal/modal"
import { Button } from "components/anti/buttons/buttons"
import { Card } from "components/anti/card/card"
import { adsScheduler } from "../../../utils/ads-scheduler"
import {setupDfpSlot} from "utils/setup-dfp-slot";


export const HomeModal = ({ data }) => {
  const [modal, setModal] = useState(null)
  const showAds = adsScheduler(data?.scheduler)

  useEffect(() => {
    setTimeout(() => {
      if (showAds) {
        setModal("pop-up")
        // setupDfpSlot({
        //   path: '/21835028929/modal-duti-staging',
        //   size: [[850,559], [425,280], [303,799]],
        //   id: 'div-gpt-ad-1690777648366-0',
        //   mapping: [
        //         [[1050,768],[850,559]],
        //         [[425,240],[425,280]],
        //         [[320,400],[300,250]]
        //   ]
        // });
      }
    }, 6000)
  }, [])



  return (
    <Modal
      id="pop-up"
      isShowing={modal}
      hide={() => setModal(null)}
      className="modal modal-lg modal-pop-up justify-content-center"
    >
      <div className="row">
        <div className={`col-md-12`}>
              <iframe id='a4fabe80' name='a4fabe80' className='popup-desktop'
                      src='https://rv.sinarmasland.com/www/delivery/afr.php?zoneid=11&amp;cb=12312' frameBorder='0'
                      scrolling='no' width='510' height='510' allow='autoplay'><a
                  href='https://rv.sinarmasland.com/www/delivery/ck.php?n=a87deb40&amp;cb=12312' target='_blank'><img
                  src='https://rv.sinarmasland.com/www/delivery/avw.php?zoneid=11&amp;cb=12312&amp;n=a87deb40'
                  border='0' alt=''/></a></iframe>

              <iframe id='aa20cfa4' name='aa20cfa4' className='popup-mobile'
                      src='https://rv.sinarmasland.com/www/delivery/afr.php?zoneid=18&amp;cb=222'
                      frameBorder='0' scrolling='no' width='250' height='250' allow='autoplay'><a
                  href='https://rv.sinarmasland.com/www/delivery/ck.php?n=a7b26e73&amp;cb=222'
                  target='_blank'><img
                  src='https://rv.sinarmasland.com/www/delivery/avw.php?zoneid=18&amp;cb=222&amp;n=a7b26e73'
                  border='0' alt=''/></a></iframe>


        </div>
      </div>
    </Modal>
  )
}
