import React, { useState, useEffect, useRef } from "react"

import { useScrollAnim } from "src/components/hooks/hooks"
import { Button } from "components/anti/buttons/buttons"
import { Card } from "components/anti/card/card"
import { Slider } from "components/anti/slider/slider"

import locationImg from "assets/img/development/location.svg"
import building from "assets/img/development/building.svg"

export const HomeHighlights = ({ data, lang, location }) => {
  const [trigger, anim] = useScrollAnim()
  const [isFirst, setIsFirst] = useState(true)

  const values = data.featured

  const [state, setState] = useState({
    nav1: null,
    nav2: null,
  })

  const [sliderIndex, setSliderIndex] = useState(0)

  const slider1 = useRef()
  const slider2 = useRef()

  useEffect(() => {
    if (location.hash === "#highlights" && isFirst) {
      const area = document.getElementById('highlights')
      window.scrollTo({
        behavior: 'smooth',
        top: area.getBoundingClientRect().top - 125
      })
      setIsFirst(false)
    }
  })

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    })
  }, [])

  const { nav1, nav2 } = state
  return (
    <section className="sc-home-highlights" ref={trigger}>
      <div className={`slider-duo slider-duo-duti`}>
        <div className="row row-2">
          <div className="slider-duo-img-wrapper col-bg col-lg-6 order-lg-first mb-sm-down-4">
            <Slider
              noGutter
              asNavFor={nav1}
              forwardRef={slider => (slider2.current = slider)}
              visibleInitial={false}
              visibleXxlDown={false}
              visibleXlDown={false}
              visibleLgDown={false}
              visibleMdDown={false}
              visibleSmDown={false}
              arrowsInitial={false}
              arrowsXxlDown={false}
              arrowsXlDown={false}
              arrowsLgDown={false}
              arrowsMdDown={false}
              arrowsSmDown={false}
              showInitial={1}
              showXxlDown={1}
              showxlDown={1}
              showLgDown={1}
              showMdDown={1}
              showSmDown={1}
              beforeChange={val => setSliderIndex(val)}
            >
              {values.map((value, i) => {
                const image =
                  value?.projectDetailsPage?.cover.image?.sourceUrl ||
                  value?.sourceUrl
                const altText = value?.projectDetailsPage?.cover.image?.altText
                return (
                  <>
                    <div id={!i ? 'highlights' : ''}>
                      <Card
                        forwardKey={i}
                        variant="boxless"
                        img={image}
                        imgRatio="r-4-3"
                        imgAlt={altText ? altText : "Image"}
                        className={anim(1, "revealBlockInUp")}
                      />
                    </div>
                  </>
                )
              })}
            </Slider>
            <div className="dv-home-progressbar pt-4 pt-md-5 pb-main">
              <div className={anim(2)}>
                <p className={`text-black font-italic bg-home-location-text`}>
                  {sliderIndex < 10 ? 0 : ""}
                  {sliderIndex + 1}/{values.length < 10 ? 0 : ""}
                  {values.length}
                </p>
              </div>
              <div className={`progress progress-container ${anim(3)}`}>
                <div
                  className="progress-bar bg-danger"
                  role="progressbar"
                  style={{
                    width: `${((sliderIndex + 1) / values.length) * 100}%`,
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <div className="col-body col-lg-6 order-lg-last slider-duo">
            <Slider
              asNavFor={nav2}
              forwardRef={slider => (slider1.current = slider)}
              fade
              visibleInitial={false}
              visibleLgDown={false}
              visibleMdDown={false}
              visibleSmDown={false}
              showInitial={1}
              showXxlDown={1}
              showxlDown={1}
              showLgDown={1}
              showMdDown={1}
              showSmDown={1}
              arrowsInitial={true}
              arrowsLgDown={true}
              arrowsXxlDown={true}
              arrowsXlDown={true}
              arrowsLgDown={true}
              arrowsMdDown={true}
              arrowsSmDown={true}
            >
              {values.map((value, i) => {
                const dataLang =
                  lang === "en" ? value : value?.translation || value
                const tag =
                  dataLang?.projectCategories?.nodes[0]?.name ||
                  value?.projectCategories?.nodes[0]?.name
                const title =
                  dataLang?.projectDetailsPage?.cover?.title ||
                  value?.projectDetailsPage?.cover?.title
                const headline =
                  dataLang?.projectDetailsPage?.headline?.text ||
                  value?.projectDetailsPage?.headline?.text

                return (
                  <div className="col-body-content container" key={i}>
                    <p
                      className={`text-uppercase font-weight-bold text-muted ${anim(
                        3
                      )}`}
                    >
                      {tag}
                    </p>
                    <h2 className={`w-lg-500px ${anim(4)}`}>{title}</h2>
                    <div className={`d-flex mb-3 ${anim(5)}`}>
                      <div className="d-flex mr-3">
                        <span>
                          <img src={locationImg} alt="icon" />
                        </span>
                        <p className="ml-2">
                          {value?.projectLocations?.nodes[0]?.name}
                        </p>
                      </div>
                      <div className="d-flex mr-3">
                        <span>
                          <img src={building} alt="icon" />
                        </span>
                        <p className="ml-2">
                          {value?.projectCategories?.nodes[0]?.name}
                        </p>
                      </div>
                    </div>
                    <p
                      className={`lead text-muted w-md-400px w-xl-500px text-truncate-fiveline ${anim(
                        6
                      )}`}
                    >
                      {headline}
                    </p>
                    <div className={`content-v-center-left ${anim(7)}`}>
                      <Button
                        variant="link"
                        className="text-white py-2"
                        link={`/development/${value?.projectCategories.nodes[0]?.slug}/${value?.slug}`}
                      >
                        {lang === "en" ? "See Details" : "Lihat Detail"}
                      </Button>
                      <Button
                        variant="link"
                        className="text-white py-2"
                        link={`${lang === "en" ? "" : "/id"}/development`}
                      >
                        {lang === "en"
                          ? "Explore All Projects"
                          : "Jelajahi Semua Proyek"}
                      </Button>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}
