import React, { useState, useEffect } from "react"

import { Button } from "components/anti/buttons/buttons"
import { useScrollAnim } from "src/components/hooks/hooks"

import BGStockPerformance1 from "assets/img/bg_stockperformance_1.png"
import BGStockPerformance2 from "assets/img/bg_stockperformance_2.png"
import BGStockPerformance3 from "assets/img/bg_stockperformance_3.png"
import StockIndicator from "assets/img/stock-indicator.png"

export const StockPerformance = ({ data, className, lang, location }) => {
  const [trigger, anim] = useScrollAnim()
  const [isFirst, setIsFirst] = useState(true)

  useEffect(() => {
    if (location?.hash === "#stock-performance" && isFirst) {
      const area = document.getElementById('stock-performance')
      window.scrollTo({
        behavior: 'smooth',
        top: area.getBoundingClientRect().top - 125
      })
      setIsFirst(false)
    }
  })

  const getDate = () => {
    const date = new Date()
    let month = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()
    let d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    let h = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    let m =
      date.getMinutes() < 10
        ? `0${date.getMinutes()}`
        : date.getMinutes() === 10
          ? 10
          : date.getMinutes()
    let y = date.getFullYear()
    return `${d}-${month}-${y}, ${h}:${m}`
  }

  return (
    <section
      className={`${className} sc-investor-stockPerformance py-main`}
      ref={trigger}
      id="stock-performance"
    >
      <img
        className="bg-image curve-1"
        src={BGStockPerformance1}
        alt="bg image"
      />
      <img
        className="bg-image curve-2"
        src={BGStockPerformance2}
        alt="bg image"
      />
      <img
        className="bg-image curve-3"
        src={BGStockPerformance3}
        alt="bg image"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <h2 className={anim(1)}>
              Duta Pertiwi <br />
              Stock Performance
            </h2>
            <p className={`mb-0 text-gray-600 ${anim(2)}`}>
              {lang === "id" ? "Terakhir Diperbarui:" : "Last Updated:"}{" "}
              <b>{data?.date || getDate()}</b>
            </p>
            <Button
              variant="link"
              link="https://finance.yahoo.com/quote/DPNS.JK/"
              className={anim(3)}
            >
              Stock Performance
            </Button>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className={`stock-performance shadow-sm ${anim(4)}`}>
              <p className="mb-4">
                DUTI &nbsp; &nbsp;
                <span className="h4 ">
                  <img
                    src={StockIndicator}
                    className={`mh-25 stock-indicator ${(data.close - data.open) > 0 ? "rotateimg180" : ""}`}
                    alt=""
                  />{" "}
                  {Math.abs(data.close - data.open)}
                </span>
              </p>
              <div className="row">
                <div className="col-6">
                  <p className="mb-0">
                    IDR <span className="h3">{data?.open || 3200}</span>
                  </p>
                </div>
                <div className="col-6">
                  <p className="mb-0">
                    Prev <span className="h3">{data?.close || 3200}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
