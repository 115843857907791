import React, { useContext, useEffect } from "react"

import { useScrollAnim } from "src/components/hooks/hooks"
import { Cover } from "components/anti/cover/cover"
import { Link } from "components/anti/link/link"
import { LoadingContext } from "src/context/loading-context"

export const HomeCover = ({ data, theme }) => {
  const { initialLoading } = useContext(LoadingContext)
  const [trigger, anim] = useScrollAnim()

  useEffect(() => {
    if (!initialLoading) {
      setTimeout(() => {
        const buttonEl = document.querySelectorAll(`.cd-home-hover`)
        buttonEl.forEach(element => {
          element.style.opacity = "1"
        })
      }, 1000)
    }
  }, [initialLoading])

  return (
    <div className="sc-home-cover position-relative" ref={trigger}>
      <Cover
        variant="basic"
        theme={theme}
        img={data?.imageMobile?.sourceUrl}
        imgMd={data?.imageDekstop?.sourceUrl}
        title={data?.title}
        text={data?.text}
        imgHeight="h-700px"
        className="global-cover position-relative"
        titleClassName="mt-4 align-cover-title"
      />

      {!initialLoading && (
        <>
          <div className="d-sm-block d-none">
            <div className="d-flex dv-home-cover-boxescontainer justify-content-end">
              {data.buttons.map((item, i) => {
                return (
                  <Link
                    to={item.url}
                    target={null}
                    className={`position-relative cd-home-cover-box ${anim(
                      (1 + i),
                      "revealInUp"
                    )}`}
                    key={i}
                  >
                    <div className={`p-3`}>
                      <img
                        src={item.icon.sourceUrl}
                        alt="icon"
                        className="img-fluid icon-button"
                      />
                      <p className="mb-0 mt-2 text-white w-150px">{item.text}</p>
                    </div>
                    <div
                      style={{ opacity: "0" }}
                      className={`p-3 cd-home-hover hover-${i}`}
                    >
                      <img
                        src={item.iconHover.sourceUrl}
                        alt="icon"
                        className="img-fluid icon-button-hover"
                      />
                      <p className="mb-0 mt-2 text-white w-150px">{item.text}</p>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
          <div className="d-block d-sm-none">
            <div className="dv-home-cover-boxescontainer">
              {data.buttons.map((item, i) => {
                return (
                  <Link
                    to={item.url}
                    className={`position-relative cd-home-cover-box ${anim(
                      (1 + i) * 2,
                      "revealInUp"
                    )}`}
                    target={null}
                    key={i}
                  >
                    <div className={`py-3`}>
                      <div className="container">
                        {/* <i className="ail ai-download"></i> */}
                        <img
                          src={item.icon.sourceUrl}
                          alt="icon"
                          className="img-fluid icon-button"
                        />
                        <div className="w-150px">
                          <p className="mb-0 text-white">{item.text}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
