import React, {useEffect} from "react"
import { adsScheduler } from "../../../utils/ads-scheduler"
//import {useDfpSlot} from "components/hooks/hooks";
//import {setupDfpSlot} from "utils/setup-dfp-slot";

export const HomeBillboard = ({ data }) => {
  const showAds = adsScheduler(data?.scheduler)

    // useEffect(() => {
    //     setTimeout(() => {
	//     setupDfpSlot({
	//     	path: '/21835028929/billboard-duti-staging',
    //         	size: [[920, 245], [600, 160], [300, 80]],
    //         	id: 'div-gpt-ad-1689831097397-0',
    //         	mapping: [
    //             	[[1050, 768], [920, 245]],
 	//                 [[768, 240], [600, 160]],
    //       	        [[320, 400], [300, 80]]
    //         	]
    //         });
	// }, 6000)
    // })


  return (
    <>
        {data && showAds && (
        <section className="py-main">
              <div className="container mw-xl revive-container">
                <ins data-revive-zoneid="12" data-revive-id="4d3dce4db16ff385dd1c5dd5ade84a88"></ins>
                <script async src="//rv.sinarmasland.com/www/delivery/asyncjs.php"></script>
              </div>
        </section>
        )}
    </>
  )
}
