import React from "react"
import moment from "moment"

import { Slider } from "components/anti/slider/slider"
import { Card } from "components/anti/card/card"
import { Button } from "components/anti/buttons/buttons"
import { useScrollAnim } from "src/components/hooks/hooks"

import defaultImg from "assets/img/bg_default.jpg"

export const HomeNews = ({ lang, data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="py-main sc-home-newsletter bg-light" ref={trigger}>
      <div className="container">
        <div className="d-flex justify-content-between">
          <h3 className={`h3 scroll-fadeInUp fadeInUp delayp1 ${anim(1)}`}>
            {lang === "en" ? "News & Events" : "Berita & Acara"}
          </h3>
          <Button
            variant="link"
            link={`${lang === "en" ? "" : "/id"}/news-events`}
            className={`mr-4 pt-sm-down-0 ${anim(2)}`}
          >
            {lang === "en" ? "SEE ALL" : "LIHAT SEMUA"}
          </Button>
        </div>
        {/* <div className="d-flex"> */}
        <Slider
          showInitial={4}
          showXxlDown={4}
          showXlDown={3}
          showLgDown={3}
          showMdDown={2}
          showSmDown={1.4}
          arrowsInitial={true}
          arrowsXxlDown={true}
          arrowsXlDown={true}
          arrowsLgDown={false}
          arrowsMdDown={false}
          arrowsSmDown={false}
          className="slider-news"
        >
          {data?.map((item, i) => {
            const image = item.featuredImage
              ? item?.featuredImage?.node?.sourceUrl
              : defaultImg
            const altText = item?.featuredImage?.node?.altText
            const title =
              lang === "en"
                ? item.title
                : item?.translation?.title || item.title
            return (
              <Card
                key={i}
                link={`${lang === "en" ? "" : "/id"}/news-events/${item.categories.nodes[0].slug
                  }/${item.slug}`}
                img={image}
                title={title}
                imgAlt={altText ? altText : "Image"}
                titleClassName="title-truncate"
                text={moment(item.date).format("DD MMM YYYY")}
                className={`cd-home-news ${anim(3 + i)}`}
                cardBodyClassName="px-0"
              />
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
