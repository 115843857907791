import React, { useState, useEffect, useRef } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"
import { Button } from "components/anti/buttons/buttons"

import { useScrollAnim } from "src/components/hooks/hooks"

gsap.registerPlugin(ScrollTrigger)
export const HomeOverview = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  const txtRunning = useRef(null)
  const [tl, setTl] = useState(
    gsap.timeline({
      paused: true,
    })
  )

  useEffect(() => {
    tl.from(txtRunning.current, {
      x: "25%",
      scrollTrigger: {
        trigger: txtRunning.current,
        start: "top 70%",
        end: "bottom",
        scrub: 1.5,
        // markers: true, // uncomment if you want to debug
        toggleActions: "play none none reverse",
      },
    })
  }, [])


  return (
    <section
      className="sc-home-overview py-main position-relative"
      ref={trigger}
    >
      <span ref={txtRunning} className="text-running text-gray-50">
        realestate & develop
      </span>
      <div className="container mw-lg">
        <div className="cover-body">
          <h2 className={`w-md-500px ${anim(1)}`}>{data.title}</h2>
          <p className={`w-md-600px ${anim(2)}`}>{data.text}</p>
        </div>
        <div className="btn-group pt-md-5">
          <div className="d-md-flex justify-content-between">
            {data.buttons.map((link, i) => {
              return (
                <div className={`${anim(3 + i)}`}>
                  <Button variant={link.icon ? "alt-link" : "link"} link={link.url} key={i}>
                    {link.icon && (
                      <span className="mr-2">
                        <img src={link.icon.sourceUrl} className="img-fluid mb-1" alt="icon" />
                      </span>
                    )}
                    {link.text}
                  </Button>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
